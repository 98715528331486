import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import React, { useEffect, useRef } from 'react';
import { DateField, Day } from '@naf/input';

export const BirthDateDay = <T extends FieldValues>({
  name,
  control,
  defaultValue,
  errorMessage,
  isDisabled,
  clearError,
  member,
  rules,
}: UseControllerProps<T> & {
  errorMessage?: string;
  isDisabled?: boolean;
  clearError?: () => void;
  member?: string;
}) => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (errorMessage && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [errorMessage]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { value, onChange } }) => (
        <DateField>
          <Day
            error={!!errorMessage}
            onChange={(val: any) => {
              onChange(val);
              if (clearError) clearError();
            }}
            value={value}
            autoComplete="bday-day"
            disabled={isDisabled}
            id={`${member || ''}birthday`}
            nextId={`${member || ''}birthmonth`}
            ref={ref}
          />
        </DateField>
      )}
    />
  );
};
